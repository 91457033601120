import { encodeSearch, getLocation, subscribeToLocation } from "@swan-io/chicane";
import { emptyToUndefined, isNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { windowSize } from "@swan-io/lake/src/utils/windowSize";
import { ReactNode, createContext, useContext, useMemo } from "react";
import { P, match } from "ts-pattern";
import { EnvType } from "../graphql/admin";
import { Router, finitePaths, finiteRouteNames } from "./routes";
import { Source } from "./session";

const API_URL = "https://swan.matomo.cloud/matomo.php";

const API_VERSION = "1"; // according to matomo documentation, currently always set to 1
const REC = "1"; // according to matomo documentation, must be set to 1
const SITE_ID = __env.CLIENT_IDENTITY_MATOMO_SITE_ID;

let sessionEnvType: EnvType | undefined = undefined;

export const sendMatomoEvent = (
  event: { type: "PageView" } | { type: "Action"; category: string; name: string },
) => {
  if (isNullishOrEmpty(SITE_ID) || isNullishOrEmpty(sessionEnvType)) {
    return; // only track production or development testing events
  }

  const route = Router.getRoute(finiteRouteNames);
  const { width, height } = windowSize.get();

  const source = Source.get().toUndefined();
  // https://developer.matomo.org/api-reference/tracking-api
  const params = {
    idsite: SITE_ID,
    rec: REC,
    apiv: API_VERSION,

    action_name: event.type,
    rand: Date.now().toString(), // random number to prevent caching
    dimension2: sessionEnvType, // defined in matomo as "environment"
    ...(source != null ? { dimension4: source } : null), // defined in matomo as "environment"

    res: `${width}x${height}`,
    url:
      window.location.origin +
      match(route?.name)
        .with(P.string, name => finitePaths[name])
        .otherwise(() => ""),

    ...match(event)
      .with({ type: "Action" }, event => ({
        ca: "1",
        e_c: event.category,
        e_a: event.type,
        e_n: event.name,
      }))
      .otherwise(() => ({})),
  };

  if ("sendBeacon" in navigator) {
    try {
      navigator.sendBeacon(API_URL + encodeSearch(params));
    } catch {}
  }
};

export const startPageViewTracking = (envType: EnvType) => {
  sessionEnvType = envType;

  let lastPath = getLocation().raw.path;
  sendMatomoEvent({ type: "PageView" });

  return subscribeToLocation(location => {
    const nextPath = location.raw.path;

    if (lastPath !== nextPath) {
      lastPath = nextPath;
      sendMatomoEvent({ type: "PageView" });
    }
  });
};

const TrackingCategoryContext = createContext<string>("");
export const useTrackingCategory = () => useContext(TrackingCategoryContext);

export const TrackingProvider = ({
  children,
  category,
}: {
  children: ReactNode;
  category: string;
}) => {
  const contextValue = useContext(TrackingCategoryContext);

  const merged = useMemo(
    () =>
      match({
        a: emptyToUndefined(contextValue.trim()),
        b: emptyToUndefined(category.trim()),
      })
        .with({ a: P.string, b: P.string }, ({ a, b }) => `${a} > ${b}`)
        .with({ a: P.string, b: P.nullish }, ({ a }) => a)
        .with({ a: P.nullish, b: P.string }, ({ b }) => b)
        .with({ a: P.nullish, b: P.nullish }, () => "Uncategorized")
        .exhaustive(),
    [contextValue, category],
  );

  return (
    <TrackingCategoryContext.Provider value={merged}>{children}</TrackingCategoryContext.Provider>
  );
};
