import { Dict } from "@swan-io/boxed";
import { createGroup, createRouter, InferRoutes, replaceUnsafe } from "@swan-io/chicane";

const routes = {
  CardWidgetLive: "/card/live/widget/:token",
  CardWidgetSandbox: "/card/sandbox/widget/:token",
  CardChoosePinLive: "/card/live/choose-pin/:token",
  CardChoosePinSandbox: "/card/sandbox/choose-pin/:token",
  ExpiredLink: "/404",
  Logout: "/logout?:logout_challenge",
  LogoutCallback: "/logout/callback",

  // Static page for end of flow where branding information isn't available
  Done: "/done",

  Login: "/login?&:step{waitingScreen}&:login_challenge",
  Consent: "/consent?:consentId",
  RegisterDevice: "/register-device?:nextUrl",

  ...createGroup("Authenticator", "/authenticator", {
    Area: "/*",

    RegisterBiometryIfApplicable: "/biometry-check?:nextUrl",
    RegisterBiometry: "/biometry?:nextUrl",

    ...createGroup("Login", "/login", {
      Root: "/?:login_challenge",
      OpenBanking: "/open-banking/:paymentSessionId?:nextUrl",
      VerifyEmail: "/verify-email?:nextUrl&:email",

      ...createGroup("Identification", "/identification?:nextUrl", {
        Area: "/*",
        Root: "/",
        // we make `flow` part of the route because of the Fourthline flow,
        // which is currently in two successive steps
        Flow: "/:flow{CheckoutExpert|CheckoutPVID|UbbleExpert|UbblePVID|FourthlineQESStepExpert|FourthlineQESStepSignature|FourthlineQESWorkflow}",
      }),
    }),

    ...createGroup("Consent", "/consent", {
      Root: "/?:consent_challenge&:consentId",
    }),

    ...createGroup("EditPhone", "/edit-phone/:projectId", {
      Area: "/*",
      Form: "/",
      VerifyOtp: "/verify-otp",
      VerifyPasscode: "/verify-passcode",
      Success: "/success",
      Blocked: "/blocked",
    }),

    ...createGroup("Recover", "/recover?:nextUrl", {
      Process: "/process",
      Passcode: "/passcode?:step",
      Success: "/success",
    }),

    Clear: "/clear",

    Granted: "/granted",
    Refused: "/refused",
  }),
} as const;

export const Router = createRouter(routes);

type Routes = InferRoutes<typeof Router>;

export type RouteName = keyof Routes;
export type RouteParams<T extends RouteName> = Routes[T];

type Paths = {
  [K in keyof typeof routes]: (typeof routes)[K] extends `${infer Path extends string}?${string}`
    ? Path
    : (typeof routes)[K];
};

export const finitePaths = Object.fromEntries(
  Dict.entries(routes)
    .map(([key, value]) => [key, value.split("?")[0] ?? ""] as const)
    .filter(([, value]) => !value.endsWith("/*")),
) as {
  [K in keyof Paths as Paths[K] extends `${string}/*` ? never : K]: Paths[K];
};

export const finiteRouteNames = Dict.keys(finitePaths);

export const safeRedirect = (
  url: string,
  { clearSession }: { clearSession: boolean } = { clearSession: false },
) => {
  const isInternalLink = url[0] === "/" || url[0] === "?" || url[0] === "#";

  if (clearSession) {
    window.sessionStorage.clear();
  }

  if (isInternalLink) {
    return replaceUnsafe(url);
  }

  const { origin } = window.location;

  if (url.startsWith(`${origin}/`)) {
    return replaceUnsafe(url.substring(origin.length));
  }

  return window.location.replace(url);
};
