import { Array, AsyncData, Dict, Option, Result } from "@swan-io/boxed";
import { ClientContext, useMutation } from "@swan-io/graphql-client";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { invariantColors } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { lowerCase } from "@swan-io/lake/src/utils/string";
import { isDecentMobileDevice } from "@swan-io/lake/src/utils/userAgent";
import { Suspense, lazy, useEffect, useMemo, useRef, useState } from "react";
import { P, match } from "ts-pattern";
import { v4 as uuid } from "uuid";
import { ErrorView } from "./components/ErrorView";
import { Layout } from "./components/Layout";
import { LoadingView } from "./components/LoadingView";
import { Redirect } from "./components/Redirect";
import { StartConsentDocument, StartConsentV2Input } from "./graphql/admin";
import { NotFoundPage } from "./pages/NotFoundPage";
import { UnsupportedLinkPage } from "./pages/UnsupportedLinkPage";
import { adminClient } from "./utils/gql";
import { TrackingProvider } from "./utils/matomo";
import { Router, safeRedirect } from "./utils/routes";
import {
  sensitiveInfoAnyDevicePattern,
  sensitiveInfoAuthenticatorPattern,
} from "./utils/sensitiveInfo";
import {
  EntryParam,
  InitialFlowUrl,
  Source,
  SubcriptionChannelNameByConsentId,
} from "./utils/session";
import { clearSignMachine, useSignMachine } from "./utils/signMachine";
import { updateTgglContext } from "./utils/tggl";

const RegisterDevice = lazy(() =>
  import("./pages/RegisterDevice").then(({ RegisterDevice }) => ({ default: RegisterDevice })),
);

const AuthenticatorArea = lazy(() =>
  import("./pages/authenticator/AuthenticatorArea").then(({ AuthenticatorArea }) => ({
    default: AuthenticatorArea,
  })),
);

const RemoteConsentPage = lazy(() =>
  import("./pages/RemoteConsentPage").then(({ RemoteConsentPage }) => ({
    default: RemoteConsentPage,
  })),
);

const RemoteLoginPage = lazy(() =>
  import("./pages/RemoteLoginPage").then(({ RemoteLoginPage }) => ({
    default: RemoteLoginPage,
  })),
);

const SensitiveInfoViewer = lazy(() =>
  import("./components/SensitiveInfoViewer").then(({ SensitiveInfoViewer }) => ({
    default: SensitiveInfoViewer,
  })),
);

export const BrandedApp = ({ entryParam }: { entryParam: EntryParam }) => {
  const signMachine = useSignMachine();

  // The two entry points are:
  // /login with `login_challenge`
  // /consent with either `consent_challenge` or `consentId`
  const route = Router.useRoute(["AuthenticatorArea", "Login", "Consent"]);

  const [startConsentInput] = useState(() => {
    return match(entryParam)
      .returnType<StartConsentV2Input>()
      .with({ loginChallenge: P.string }, ({ loginChallenge }) => ({
        startOAuth2Login: { loginChallenge },
      }))
      .with({ consentChallenge: P.string }, ({ consentChallenge }) => ({
        startOAuth2Consent: { consentChallenge },
      }))
      .with({ consentId: P.string, env: P.string }, ({ consentId, env }) => ({
        startConsentById: {
          consentId,
          env,
          isMobile: isDecentMobileDevice,
          channelName: match(
            SubcriptionChannelNameByConsentId.get()
              .flatMap(namesById => Option.fromNullable(namesById[consentId]))
              .map(item => item.channelName),
          )
            .with(Option.P.Some(P.select()), channelName => channelName)
            // If no channelName is already known, generate a new one
            .otherwise(() => {
              const channelName = uuid();
              const now = Date.now();
              const expireAt = now + 1000 * 60 * 60; // one hour
              SubcriptionChannelNameByConsentId.update(value => ({
                ...value
                  .map(values =>
                    Dict.fromEntries(
                      Array.filterMap(Dict.entries(values), ([key, value]) =>
                        now <= value.expireAt ? Option.Some([key, value] as const) : Option.None(),
                      ),
                    ),
                  )
                  .getOr({}),
                [consentId]: { channelName, expireAt },
              }));
              return channelName;
            }),
          source: Source.get().toUndefined(),
        },
      }))
      .exhaustive();
  });

  const [startConsent, consent] = useMutation(StartConsentDocument);

  useEffect(() => {
    match(signMachine)
      .with(AsyncData.P.Done(P.select()), signMachine => {
        startConsent(
          { input: startConsentInput },
          { overrides: { headers: signMachine.map(({ headers }) => headers).toUndefined() } },
        ).tapOk(consent => {
          match(consent)
            .with(
              {
                startConsentV2: {
                  __typename: "StartConsentV2SuccessPayload",
                  consentInfo: {
                    __typename: "OAuth2ConsentInfo",
                    flowInitialUrl: P.select(P.string),
                  },
                },
              },
              initialFlowUrl => {
                InitialFlowUrl.set(initialFlowUrl);
              },
            )
            .with(
              {
                startConsentV2: {
                  __typename: "StartConsentV2PhoneNumberMismatchRejection",
                },
              },
              () => {
                // authenticator doesn't match the expected number,
                // clearing the sign machine and rerun the startConsent call
                clearSignMachine();
              },
            )
            .otherwise(() => {});
        });
      })
      .otherwise(() => {});
  }, [signMachine, startConsent, startConsentInput]);

  // While the sign machine is updated, the `startConsent` reloads
  // This keeps the UI stable on screen in the meantime
  const lastConsentRef = useRef(consent);

  useEffect(() => {
    if (consent.isDone()) {
      lastConsentRef.current = consent;
    }
  }, [consent]);

  const consentToShow = consent.isLoading() ? lastConsentRef.current : consent;

  const projectInfo = useMemo(
    () =>
      consentToShow.mapOk(startConsent =>
        match(startConsent)
          .with(
            { startConsentV2: P.select({ __typename: "StartConsentV2SuccessPayload" }) },
            startConsent =>
              match(startConsent.consentInfo)
                .with({ __typename: "OAuth2ConsentInfo" }, consentInfo =>
                  Option.fromNullable(consentInfo.oauth2ProjectInfo).map(projectInfo => ({
                    env: consentInfo.env,
                    projectInfo,
                  })),
                )
                .with({ __typename: "OperationConsentInfo" }, consentInfo =>
                  Option.Some({ env: consentInfo.env, projectInfo: consentInfo.projectInfo }),
                )
                .otherwise(() => Option.None()),
          )
          .otherwise(() => Option.None()),
      ),
    [consentToShow],
  );

  // We allow selected partners to have their own subdomain, but links
  // initially point to ours. If they differ, we start by a redirection
  // to the specified custom one
  const urlToRedirectTo = useMemo(() => {
    return projectInfo.mapOk(info => {
      return info
        .flatMap(({ projectInfo }) => Option.fromNullable(projectInfo.customConsentSubdomain))
        .flatMap(customConsentSubdomain => {
          const url = new URL(window.location.href);
          const [currentSubdomain, ...envHostName] = url.hostname.split(".");

          if (currentSubdomain !== customConsentSubdomain) {
            url.hostname = [customConsentSubdomain, ...envHostName].join(".");
            return Option.Some(url.toString());
          } else {
            return Option.None();
          }
        });
    });
  }, [projectInfo]);

  useEffect(() => {
    match(projectInfo)
      .with(AsyncData.P.Done(Result.P.Ok(Option.P.Some(P.select()))), info => {
        updateTgglContext({
          environmentType: lowerCase(info.env),
          projectId: info.projectInfo.id,
        });
      })
      .otherwise(() => {});
  }, [projectInfo]);

  return (
    match(
      AsyncData.allFromDict({
        startConsentPayload: consentToShow
          .mapOkToResult(consent =>
            Option.fromNullable(consent.startConsentV2).toResult(new Error("No consent")),
          )
          .mapOkToResult(filterRejectionsToResult),
        projectInfo,
        urlToRedirectTo,
        signMachine,
      }).map(({ startConsentPayload, projectInfo, urlToRedirectTo, signMachine }) => {
        return Result.allFromDict({ startConsentPayload, projectInfo, urlToRedirectTo }).map(
          values => ({ ...values, signMachine }),
        );
      }),
    )
      .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
        <LoadingView id="BrandedApp.Loading" />
      ))
      // Phone mismatch between OTP phone number and current authenticator,
      // show a loading while we clear the sign machine and re-run the startConsent
      .with(
        AsyncData.P.Done(
          Result.P.Error({ __typename: "StartConsentV2PhoneNumberMismatchRejection" }),
        ),
        () => <LoadingView id="BrandedApp.StartConsentV2PhoneNumberMismatchRejection" />,
      )
      .with(AsyncData.P.Done(Result.P.Error(P.select())), error => (
        <ErrorView error={"__typename" in error ? undefined : error} />
      ))
      .with(
        AsyncData.P.Done(
          Result.P.Ok({
            urlToRedirectTo: Option.Some(P.select("urlToRedirectTo")),
            startConsentPayload: P.select("consent"),
          }),
        ),
        ({ urlToRedirectTo }) => {
          const redirectTo = new URL(urlToRedirectTo);
          // sessionStorage will not be shared across subdomains, meaning we have to pass
          // the information through URL
          redirectTo.searchParams.append(
            "channel",
            btoa(JSON.stringify(SubcriptionChannelNameByConsentId.get().getOr({}))),
          );
          window.location.replace(redirectTo.toString());
          // Avoid rendering anything else while redirecting
          return <LoadingView id="BrandedApp.WaitingForRedirect" />;
        },
      )
      .with(
        AsyncData.P.Done(Result.P.Ok(P.select())),
        ({ startConsentPayload, projectInfo, signMachine }) => {
          return (
            match(startConsentPayload)
              .with(
                {
                  __typename: "StartConsentV2SuccessRedirectPayload",
                  redirectUrl: P.select(),
                },
                redirectUrl => {
                  return <Redirect to={redirectUrl} clearSession={true} />;
                },
              )
              .with(
                {
                  __typename: "StartConsentV2ViewSensitiveInfoPayload",
                  consentInfo: { __typename: "OperationConsentInfo" },
                },
                consent => {
                  const state = Result.fromExecution(
                    () => JSON.parse(consent.consentCallbackResponseState) as unknown,
                  )
                    .toOption()
                    .map(value => (typeof value == "object" && value !== null ? value : null))
                    .map(state => ({
                      ...state,
                      purpose: consent.consentInfo.purpose,
                      envType: consent.consentInfo.env,
                      redirectTo: consent.redirectUrl,
                    }));

                  const allowsDesktopAuthentication = projectInfo
                    .map(({ projectInfo }) => projectInfo.allowsDesktopAuthentication)
                    .getOr(false);

                  const isAuthenticator = isDecentMobileDevice || allowsDesktopAuthentication;

                  return match({ state, isAuthenticator })
                    .with(
                      {
                        state: Option.P.Some(P.select(sensitiveInfoAuthenticatorPattern)),
                        isAuthenticator: true,
                      },
                      sensitiveInfo => (
                        <Suspense
                          fallback={<LoadingView id="BrandedApp.SensitiveInfo.Authenticator" />}
                        >
                          <SensitiveInfoViewer
                            sensitiveInfo={sensitiveInfo}
                            onClose={redirectTo => safeRedirect(redirectTo, { clearSession: true })}
                          />
                        </Suspense>
                      ),
                    )
                    .with(
                      {
                        state: Option.P.Some(P.select(sensitiveInfoAnyDevicePattern)),
                        isAuthenticator: false,
                      },
                      sensitiveInfo => (
                        <Suspense fallback={<LoadingView id="BrandedApp.SensitiveInfo.Remote" />}>
                          <SensitiveInfoViewer
                            sensitiveInfo={sensitiveInfo}
                            onClose={redirectTo => safeRedirect(redirectTo, { clearSession: true })}
                          />
                        </Suspense>
                      ),
                    )
                    .otherwise(() => <Redirect to={consent.redirectUrl} clearSession={true} />);
                },
              )
              // impossible case
              .with({ __typename: "StartConsentV2ViewSensitiveInfoPayload" }, () => <ErrorView />)
              .with({ __typename: "StartConsentV2SuccessPayload" }, startConsentPayload => {
                const allowsDesktopAuthentication = projectInfo
                  .map(({ projectInfo }) => projectInfo.allowsDesktopAuthentication)
                  .getOr(false);

                const isAuthenticator = isDecentMobileDevice || allowsDesktopAuthentication;

                const accentColor = projectInfo
                  .flatMap(({ projectInfo }) => Option.fromNullable(projectInfo.accentColor))
                  .getOr(invariantColors.gray);

                const clientName = projectInfo
                  .map(({ projectInfo }) => projectInfo.name)
                  .toUndefined();

                const clientLogo = projectInfo
                  .flatMap(({ projectInfo }) => Option.fromNullable(projectInfo.logoUri))
                  .toUndefined();

                const envType = match(startConsentPayload.consentInfo)
                  .with({ __typename: "OAuth2ConsentInfo" }, ({ env }) => env)
                  .with({ __typename: "OperationConsentInfo" }, ({ env }) => env)
                  .otherwise(() => "Live" as const);

                const projectId = projectInfo
                  .map(({ projectInfo }) => projectInfo.id)
                  .toUndefined();

                const userInfo = match(startConsentPayload.consentInfo)
                  .with(
                    { __typename: "OAuth2ConsentInfo" },
                    consentInfo => consentInfo.parameters.userInfo,
                  )
                  .with({ __typename: "OperationConsentInfo" }, consentInfo => consentInfo.userInfo)
                  .exhaustive();

                const sessionUserInfo = match(startConsentPayload.consentInfo)
                  .with(
                    { __typename: "OAuth2ConsentInfo" },
                    consentInfo => consentInfo.sessionUserInfo ?? undefined,
                  )
                  .otherwise(() => undefined);

                const consentInfo = startConsentPayload.consentInfo;

                return (
                  <WithPartnerAccentColor color={accentColor}>
                    <Layout clientName={clientName} clientLogo={clientLogo} envType={envType}>
                      {match({ ...route, isAuthenticator })
                        .with({ name: "AuthenticatorArea", isAuthenticator: false }, () => (
                          <UnsupportedLinkPage />
                        ))
                        .with({ name: "AuthenticatorArea" }, () =>
                          match(signMachine)
                            .with(Option.P.None, () => (
                              <Suspense fallback={<LoadingView id="BrandedApp.RegisterDevice" />}>
                                <RegisterDevice
                                  userInfo={userInfo}
                                  sessionUserInfo={sessionUserInfo}
                                  consentInfoType={consentInfo.__typename}
                                />
                              </Suspense>
                            ))
                            .with(Option.P.Some(P.select()), signMachine => (
                              <TrackingProvider category="Authenticator">
                                <ClientContext.Provider value={adminClient}>
                                  <Suspense
                                    fallback={<LoadingView id="BrandedApp.Authenticator" />}
                                  >
                                    <AuthenticatorArea
                                      envType={envType}
                                      projectId={projectId}
                                      onboardingId={match(consentInfo)
                                        .with(
                                          {
                                            __typename: "OAuth2ConsentInfo",
                                            parameters: { onboardingId: P.select() },
                                          },
                                          onboardingId => onboardingId ?? undefined,
                                        )
                                        .otherwise(() => undefined)}
                                      accountMembershipId={match(consentInfo)
                                        .with(
                                          {
                                            __typename: "OAuth2ConsentInfo",
                                            parameters: { accountMembershipId: P.select() },
                                          },
                                          accountMembershipId => accountMembershipId ?? undefined,
                                        )
                                        .otherwise(() => undefined)}
                                      consentInfo={consentInfo}
                                      userInfo={userInfo}
                                      signMachine={signMachine}
                                    />
                                  </Suspense>
                                </ClientContext.Provider>
                              </TrackingProvider>
                            ))
                            .exhaustive(),
                        )
                        .with(
                          {
                            name: "Login",
                            params: { login_challenge: P.string },
                            isAuthenticator: true,
                          },
                          ({ params: { login_challenge } }) => {
                            return (
                              <Redirect to={Router.AuthenticatorLoginRoot({ login_challenge })} />
                            );
                          },
                        )
                        .with(
                          { name: "Login", params: { login_challenge: P.string } },
                          ({ params: { step, login_challenge } }) =>
                            match(startConsentPayload.consentInfo)
                              .with({ __typename: "OAuth2ConsentInfo" }, consentInfo => (
                                <Suspense fallback={<LoadingView id="BrandedApp.Login" />}>
                                  <RemoteLoginPage
                                    step={step}
                                    loginChallenge={login_challenge}
                                    userInfo={userInfo}
                                    sessionUserInfo={Option.fromNullable(
                                      consentInfo.sessionUserInfo,
                                    )}
                                  />
                                </Suspense>
                              ))
                              .otherwise(() => <NotFoundPage />),
                        )
                        .with(
                          {
                            name: "Consent",
                            params: { consentId: P.string },
                            isAuthenticator: true,
                          },
                          ({ params: { consentId } }) => {
                            return <Redirect to={Router.AuthenticatorConsentRoot({ consentId })} />;
                          },
                        )
                        .with({ name: "Consent" }, () =>
                          match(consentInfo)
                            .with({ __typename: "OperationConsentInfo" }, consentInfo => (
                              <Suspense fallback={<LoadingView id="BrandedApp.Consent" />}>
                                <RemoteConsentPage envType={envType} consentInfo={consentInfo} />
                              </Suspense>
                            ))
                            .otherwise(() => <NotFoundPage />),
                        )
                        .otherwise(() => (
                          <NotFoundPage />
                        ))}
                    </Layout>
                  </WithPartnerAccentColor>
                );
              })
              .exhaustive()
          );
        },
      )
      .exhaustive()
  );
};
